:root {
  --scrollbarWidth: 0px;
  --columnCount: 5;
  --viewportWidth: calc(100vw - var(--scrollbarWidth));
}

html {
  font-size: 80%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-width: var(--viewportWidth);
  width: max-content;
}

.sticky-vertical {
  position: sticky;
  left: 0;
  box-sizing: border-box;
  width: var(--viewportWidth);
}

.viewportWidth {
  width: var(--viewportWidth) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiAlert-standardSuccess {
  background-color: rgba(86, 252, 3) !important;
}

.grecaptcha-badge {
  opacity: 0;
}
